import { Box, GlobalStyles, useTheme } from "@mui/material";
import { Footer } from "../footer";
import { HEADER_ITEMS } from "../header";
import { Header, HeaderOffset } from "@cm-websites/common";
import React from "react";

export type NavigationAndFooterLayoutProps = { children: React.ReactNode };

export const NavigationAndFooterLayout: React.FC<NavigationAndFooterLayoutProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <GlobalStyles styles={{ body: { background: theme.palette.grey[800] } }} />
      <Box sx={{ background: "white", pb: 9 }}>
        <Header items={HEADER_ITEMS} />
        <HeaderOffset />
        {children}
      </Box>
      <Footer />
    </React.Fragment>
  );
};
