import { FooterContainer, FooterMenus, FooterPaymentsNotice } from "@cm-websites/common";
import { Container, Link, Typography } from "@mui/material";
import React from "react";

export const Footer: React.FC = () => (
  <FooterContainer>
    <Container maxWidth="xl">
      <Typography textAlign="center" variant="h5" sx={{ width: "100%", pb: { xs: 2, lg: 3 } }}>
        Legal Notice
      </Typography>

      <Typography textAlign="center" sx={{ pb: 3, margin: "0 auto", maxWidth: "68ch" }} variant="body2">
        This service is operated by Culinario Mortale
        <br />
        Bischoff, Kauffmann & Otani GbR
        <br />
        Hohenzollerndamm 152, 14199 Berlin, Germany
        <br />
      </Typography>

      <Typography textAlign="center" sx={{ pb: 3, margin: "0 auto", maxWidth: "68ch" }} variant="body2">
        <Link
          color="inherit"
          underline="hover"
          href="https://www.culinario-mortale.co.uk/terms-and-conditions/"
        >
          Terms and Conditions
        </Link>
      </Typography>
    </Container>
  </FooterContainer>
);
